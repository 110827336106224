import styled from 'styled-components';

const Scrollable = styled.div`
  justify-content: flex-start;
  grid-column: 1/-1;
  width: 100%;
  display: flex;
  gap: 20px;
  left: 0;
  overflow-x: auto;
  scrollbar-width: none;
  &::-webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Scrollable;
