import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { withTheme } from 'styled-components';
import SEO from '../components/common/Seo';
import localize from '../components/common/localize';
import PreFooter from '../components/common/PreFooter';
import Hero from '../components/common/Hero';
import TaggedGallery from '../components/pages/gallery/TaggedGallery';

function Gallery({ data }) {
  const { page, tags, initialImages } = data;
  const tagNames = tags.edges.map(({ node }) => node.name);

  return (
    <>
      <SEO title={page.title} description={page.description} />
      <Hero
        title={page.heroTitle}
        text={page.heroText}
        paddedBottom={false}
        colorScheme="maroon"
      />
      <TaggedGallery
        initialImages={initialImages}
        tags={tagNames}
        colorScheme="maroon"
      />
      <PreFooter />
    </>
  );
}

export default localize(withTheme(Gallery));

export const query = graphql`
  query {
    page: sanityGalleryPage {
      title {
        localized
      }
      description {
        localized
      }
      heroTitle {
        localized
      }
      heroText {
        localized
      }
    }
    tags: allSanityGalleryItemTag {
      edges {
        node {
          name {
            localized
          }
        }
      }
    }
    initialImages: paginatedCollectionPage(
      collection: { name: { eq: "galleryImages" } }
      index: { eq: 0 }
    ) {
      nodes
      hasNextPage
      nextPage {
        id
      }
      collection {
        id
      }
    }
  }
`;

Gallery.propTypes = {
  data: PropTypes.object.isRequired,
};
