const clampAspectToBounds = (obj, rect) => ({
  width: Math.min(
    obj.width,
    rect.width,
    (obj.width * rect.height) / obj.height
  ),
  height: Math.min(
    obj.height,
    rect.height,
    (obj.height * rect.width) / obj.width
  ),
});

export default clampAspectToBounds;
