import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from './Grid';
import SplitLines from './SplitLines';
import { H1, H4 } from './Typography';

import { minWidth, maxWidth } from '../../utils/breakpoints';
import { fadeInUp, stagger } from '../../utils/transitions';
import useWaypoint from '../../hooks/useWaypoint';
import { getHeroColors } from '../../utils/colors';

const Wrapper = styled(Grid)`
  ${({ colorScheme }) => getHeroColors(colorScheme)}
`;

const Title = styled(H1)`
  text-align: center;
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col 3 / span 8;
  }
`;

const Description = styled(H4)`
  text-align: center;
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col 3 / span 8;
  }
`;

export default function Hero({
  className,
  title,
  text,
  colorScheme,
  paddedTop = true,
  paddedBottom = true,
}) {
  const [ref, visible] = useWaypoint();

  return (
    <Wrapper
      ref={ref}
      className={className}
      colorScheme={colorScheme}
      paddedTop={paddedTop}
      paddedBottom={paddedBottom}
    >
      <Title css={fadeInUp(visible)}>{title}</Title>
      <Description as="p">
        <SplitLines visible={visible} delay={stagger} textAlign="center">
          {text}
        </SplitLines>
      </Description>
    </Wrapper>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  colorScheme: PropTypes.string,
  paddedTop: PropTypes.bool,
  paddedBottom: PropTypes.bool,
};
