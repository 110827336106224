import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import UnstyledButton from '../../common/UnstyledButton';
import { fadeIn } from '../../../utils/transitions';
import fluidRange from '../../../utils/fluidRange';
import { minWidth } from '../../../utils/breakpoints';
import Scrollable from '../../common/Srollable';

const Wrapper = styled(Scrollable)`
  ${fluidRange({
    prop: 'padding-top',
    fromSize: '45px',
    toSize: '100px',
  })}
  padding-inline: ${({ gutter }) => gutter}px;
  ${minWidth('tabletPortrait')} {
    justify-content: center;
    padding-inline: 0;
  }
`;

const Tag = styled(UnstyledButton)`
  ${fluidRange({
    prop: 'font-size',
    fromSize: '18px',
    toSize: '20px',
  })};
  margin: 0;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  white-space: nowrap;
`;

export default function Tags({
  tags,
  activeTag,
  setActiveTag,
  visible,
  delay,
  order,
  gutter = 32,
}) {
  return (
    <Wrapper css={fadeIn(visible, order, delay)} gutter={gutter}>
      {['All', ...tags].map((tag, i) => (
        <Tag
          key={`${i}-${tag}`}
          active={activeTag === tag}
          onClick={() => setActiveTag(tag)}
        >
          {tag}
        </Tag>
      ))}
    </Wrapper>
  );
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTag: PropTypes.string.isRequired,
  setActiveTag: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  delay: PropTypes.number,
  order: PropTypes.number,
  gutter: PropTypes.number,
};
