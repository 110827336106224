/* eslint-disable import/no-unresolved -- Lightbox imports give errors */
import React from 'react';
import PropTypes from 'prop-types';
import ReactLightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import clampAspectToBounds from '../../../utils/clampAspectToBounds';
import { P } from '../../common/Typography';

const Video = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Image = styled(GatsbyImage)`
  max-width: min(${({ imageWidth }) => imageWidth}px, 100%);
  max-height: min(${({ imageHeight }) => imageHeight}px, 100%);
`;

const Caption = styled(P)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledLightbox = styled(ReactLightbox)`
  z-index: 1000001;
`;

const Lightbox = ({
  isOpen,
  onClose,
  slides,
  currentIndex,
  currentLocaleId,
}) => {
  const maxWidth = 1920;
  const maxHeight = 1080;

  return (
    <StyledLightbox
      open={isOpen}
      index={currentIndex}
      close={onClose}
      slides={slides}
      carousel={{
        padding: '5%',
      }}
      render={{
        slide: function getSlide({ slide, rect }) {
          if (slide.videoEmbedUrl) {
            return (
              <Video
                id={slide.id}
                {...clampAspectToBounds(
                  { width: maxWidth, height: maxHeight },
                  rect
                )}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                src={slide.videoEmbedUrl}
                title={slide.description && slide.description[currentLocaleId]}
              />
            );
          }
          const { width: imageWidth, height: imageHeight } =
            clampAspectToBounds(getImage(slide.image.image.asset), rect);
          return (
            <Image
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              image={slide.image.image.asset.gatsbyImageData}
              alt={slide.image.alt[currentLocaleId]}
            />
          );
        },
        slideFooter: function getSlideFooter({ slide }) {
          return slide.description ? (
            <Caption>{slide.description[currentLocaleId]}</Caption>
          ) : null;
        },
      }}
    />
  );
};

Lightbox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentIndex: PropTypes.number.isRequired,
  currentLocaleId: PropTypes.oneOf(['en', 'fr']).isRequired,
};

export default Lightbox;
