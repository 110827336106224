import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import MasonryComponent from 'react-masonry-css';
import Grid from '../../common/Grid';
import Button from '../../common/Button';
import { getHeroColors } from '../../../utils/colors';
import useWaypoint from '../../../hooks/useWaypoint';
import Tags from './Tags';
import { fadeIn } from '../../../utils/transitions';
import fluidRange from '../../../utils/fluidRange';
import theme from '../../../utils/theme';
import LocaleContext from '../../../context/LocaleContext';
import fetchLocalJson from '../../../utils/fetchLocal';
import Lightbox from './Lightbox';

const MAX_GUTTER = 32;
const MASONRY_CLASSNAME = 'masonry';

const Wrapper = styled(Grid)`
  ${({ colorScheme }) => getHeroColors(colorScheme)}
`;

const masonryPadding = fluidRange({
  prop: 'padding-inline',
  fromSize: `${MAX_GUTTER / 4}px`,
  toSize: `${MAX_GUTTER / 2}px`,
});

const Inner = styled.div`
  ${masonryPadding};
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Masonry = styled(MasonryComponent)`
  display: flex;
  width: auto;

  & > .${MASONRY_CLASSNAME}_column {
    ${masonryPadding};
    background-clip: padding-box;
  }

  & > .${MASONRY_CLASSNAME}_column > div {
    ${fluidRange({
      prop: 'margin-bottom',
      fromSize: `${MAX_GUTTER / 2}px`,
      toSize: `${MAX_GUTTER}px`,
    })};
  }
`;

export default function TaggedGallery({
  initialImages,
  tags,
  colorScheme,
  children,
}) {
  const { locale: currentLocale } = useContext(LocaleContext);
  const [selectedId, setSelectedId] = useState(-1);
  const [activeTag, setActiveTag] = useState('All');
  const [ref, visible] = useWaypoint({ threshold: 0.05 });

  const collectionId = initialImages.collection.id;
  const [latestPage, setLatestPage] = useState(initialImages);
  const [currentImages, setCurrentImages] = useState(initialImages.nodes);
  const [filteredImages, setFilteredImages] = useState([]);

  const { totalTagCounts } = initialImages.nodes[0].info;

  useMemo(() => {
    if (activeTag === 'All') {
      setFilteredImages(currentImages);
    } else {
      setFilteredImages(
        currentImages.filter(({ tags: t }) => {
          const tagNames = t.map(({ name }) => name[currentLocale.id]);
          return tagNames.includes(activeTag);
        })
      );
    }
  }, [activeTag, currentImages, currentLocale]);

  const loadNextPage = useCallback(async () => {
    if (!latestPage.hasNextPage) return;
    const nextPageId = latestPage.nextPage.id;
    const json = await fetchLocalJson(
      `/paginated-data/${collectionId}/${nextPageId}.json`
    );

    if (!json || json.nodes === undefined) return;

    setCurrentImages((state) => [...state, ...(json.nodes || [])]);
    setLatestPage(json);
  }, [latestPage, collectionId]);

  return (
    <Wrapper paddedBottom ref={ref} colorScheme={colorScheme}>
      <Tags
        tags={tags}
        activeTag={activeTag}
        setActiveTag={setActiveTag}
        visible={visible}
        order={2}
        delay={0.7}
        gutter={MAX_GUTTER}
      />
      <Inner css={fadeIn(visible, 3, 0.5)}>
        <Masonry
          breakpointCols={{
            [theme.breakpoints.mobile]: 1,
            [theme.breakpoints.tabletPortrait]: 2,
            [theme.breakpoints.desktop]: 3,
            default: 3,
          }}
          className={MASONRY_CLASSNAME}
          columnClassName={`${MASONRY_CLASSNAME}_column`}
        >
          {filteredImages.map(({ id, image }, i) => (
            <GatsbyImage
              key={id}
              onClick={() => setSelectedId(i)}
              image={image.image.asset.gatsbyImageData}
              alt={image.alt[currentLocale]}
            />
          ))}
          {children}
        </Masonry>
        {latestPage.hasNextPage &&
          filteredImages.length < totalTagCounts[activeTag] && (
            <Button arrow={false} color="maroon" onClick={loadNextPage}>
              Load More
            </Button>
          )}
      </Inner>
      <Lightbox
        isOpen={selectedId >= 0}
        onClose={() => setSelectedId(-1)}
        slides={filteredImages}
        currentIndex={selectedId}
        currentLocaleId={currentLocale.id}
      />
    </Wrapper>
  );
}

TaggedGallery.propTypes = {
  initialImages: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  colorScheme: PropTypes.string.isRequired,
  children: PropTypes.node,
};
