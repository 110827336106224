import { withPrefix } from 'gatsby';

const fetchLocalJson = async (pathStr) => {
  const path = withPrefix(pathStr);
  const res = await fetch(path);
  const json = await res.json();

  return json;
};

export default fetchLocalJson;
